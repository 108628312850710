import React, { useState } from "react";
import { BsArrowRightCircleFill, BsArrowUpRight } from "react-icons/bs";

///values
import image1Url from "../assets/images/Values_02.jpg";
import image2Url from "../assets/images/Pic3-FinancialEnpowerment.jpg";
import image3Url from "../assets/images/Pic4-PersonalGrowth.jpg";
import defaultImageUrl from "../assets/images/Values_01.jpg";

//featured products
import LBB from "../assets/images/Products_LBB.webp";
import LArc from "../assets/images/Products_LArc.webp";
import LeMiele from "../assets/images/Products_LeMiele.jpg";

//wellness pillars
import parallaxBg from "../assets/images/WellnessPillarsText.webp";
import wellnessPillars1 from "../assets/images/WellnessPillars_01.webp";
import wellnessPillars2 from "../assets/images/WellnessPillars_02.webp";
import wellnessPillars3 from "../assets/images/WellnessPillars_03.jpg";
import wellnessPillars4 from "../assets/images/WellnessPillars_04.jpg";
import wellnessPillars5 from "../assets/images/WellnessPillars_05.jpg";
import wellnessPillars6 from "../assets/images/WellnessPillars_06.webp";

import { Parallax } from "react-parallax";
import Modal from "../components/modal";
import ModalContent from "../components/modalcontent";
import Gallery from "../components/gallery";

const Home = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(defaultImageUrl);
  function hover1() {
    setBackgroundImage(image1Url);
  }
  function hover2() {
    setBackgroundImage(image2Url);
  }
  function hover3() {
    setBackgroundImage(image3Url);
  }

  return (
    <div className="section-vw100">
      <div className="bg-primary">
        <div className="wrapper">
          <div className="bg-image">
            <div className="hero-col">
              <div className="hero-bg-col-1">
                <div className="header-title-left">EMPOWERING</div>
                <div className="header-subtitle section-detail">
                  Holistic, Wellness, Redefined
                </div>
              </div>

              <div className="hero-bg-col-2">
                <div className="header-title-right">YOUR GLOW</div>
              </div>
            </div>

            <div className="btn-hero-group">
              <a className="btn btn-hero">
                <div className="btn-text"> Explore your glow up journey</div>
                <div className="btn-icon">
                  <BsArrowRightCircleFill size={20} />
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="section-vw100 bg-white ">
          <div className="section section-intro">
            <div className="section-item-1">
              <div className="section-tag">
                <div className="dot bg-active "></div>Introduction
              </div>
              <div className="section-title">
                Welcome to
                <p>Lavisha Malaysia</p>
              </div>
            </div>

            <div className="section-item-2">
              <div className="section-detail">
                A place where we ignite your inner and outer glow! We're
                passionate collective of beauty and wellness entrepreneurs
                dedicated to empowering individuals on their holistic
                well-being journey. Lavisha goes beyond just products - we offer
                a transformative experience that nourishes your body, mind and
                spirit.
              </div>

              <div className="section-button">
                <button className="btn btn-primary ">
                  <div className="btn-text">Read More</div>
                  <div className="btn-icon">
                    <BsArrowRightCircleFill size={20} />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="section-bg bg-white section-shadow">
          <div className="section section-value">
            <div className="section-item-1">
              <div className="section-tag">
                <div className=" dot bg-active "></div>Our Goals Align with
              </div>
              <div className="section-title">Our Values</div>
              <div className="btn-value-group-web">
                <div
                  className=" btn-value"
                  onMouseOver={hover1}
                  onMouseLeave={() => setBackgroundImage(defaultImageUrl)}
                >
                  <div className="section-detail-value">
                    <div className="main section-detail-large">
                      Holistic Wellness
                    </div>
                    <div className="section-detail-btn section-detail">
                      We believe true well-being encompasses physical health,
                      mental clarity, and emotional fulfillment
                    </div>
                  </div>
                </div>

                <div
                  className=" btn-value"
                  onMouseOver={hover2}
                  onMouseLeave={() => setBackgroundImage(defaultImageUrl)}
                >
                  <div className="section-detail-value">
                    <div className="main section-detail-large">
                      Financial Empowerment
                    </div>
                    <div className="section-detail-btn section-detail">
                      We equip you with the tools and resources to achieve your
                      financial goals and live abundantly.
                    </div>
                  </div>
                </div>
                <div
                  className=" btn-value"
                  onMouseOver={hover3}
                  onMouseLeave={() => setBackgroundImage(defaultImageUrl)}
                >
                  <div className="section-detail-value">
                    <div className="main section-detail-large">
                      Personal Growth
                    </div>
                    <div className="section-detail-btn section-detail">
                      We foster a supportive environment that encourage
                      self-discovery, confidence and continuous learning
                    </div>
                  </div>
                </div>
              </div>

              <div className="btn-value-group-mobile">
                <div className=" btn-value">
                  <img className="btn-value-img" src={image1Url}></img>
                  <div className="section-detail-value">
                    <div className="main section-detail-large">
                      Holistic Wellness
                    </div>
                    <div className="section-detail-btn section-detail">
                      We believe true well-being encompasses physical health,
                      mental clarity, and emotional fulfillment
                    </div>
                  </div>
                </div>

                <div className=" btn-value">
                  <img className="btn-value-img" src={image2Url}></img>
                  <div className="section-detail-value">
                    <div className="main section-detail-large">
                      Financial Empowerment
                    </div>
                    <div className="section-detail-btn section-detail">
                      We equip you with the tools and resources to achieve your
                      financial goals and live abundantly.
                    </div>
                  </div>
                </div>
                <div className=" btn-value">
                  <img className="btn-value-img" src={image3Url}></img>
                  <div className="section-detail-value">
                    <div className="main section-detail-large">
                      Personal Growth
                    </div>
                    <div className="section-detail-btn section-detail">
                      We foster a supportive environment that encourage
                      self-discovery, confidence and continuous learning
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-item-2">
              <div className="img-value-web">
                <img className="img-value" src={backgroundImage} />
              </div>
            </div>
          </div>
        </div>

        <div className="section-vw100  ">
          <div className="section-feature">
            <div className="section-feature-title-group">
              <div className="section-tag">
                <div className="dot bg-active"></div>What's Popular?
              </div>
              <div className="section-title section-feature-title">
              Holistic Wellness Division
              </div>
              <div className="section-feature-desc section-detail">
                Discover our curated selection of wellness categories
              </div>
            </div>

            <div className="section-feature-cards">
              <div className="wrap">
                <div className="el-group">
                  <div className="el">
                    <p className="feature-text section-detail-large">
                    LBB Skin Care
                      <BsArrowUpRight></BsArrowUpRight>
                    </p>
                    <img className="el-image" src={LBB} />
                    <div className="el-button-group">
                      <button className="el-button">Beauty</button>
                    </div>
                  </div>
                  <div className="el-image-shadow">
                    <img className="shadow" src={LBB} />
                  </div>
                </div>
                <div className="el-group">
                  <div className="el">
                    <p className="feature-text section-detail-large">
                   Holistic Wellness
                      <BsArrowUpRight></BsArrowUpRight>
                    </p>
                    <img className="el-image" src={LeMiele} />
                    <div className="el-button-group">
                      <button className="el-button">Athleisure</button>
                    </div>
                  </div>
                  <div className="el-image-shadow">
                    <img className="shadow" src={LeMiele} />
                  </div>
                </div>
                <div className="el-group">
                  <div className="el">
                    <p className="feature-text section-detail-large">
                      L'Arc Lavisha SPA<BsArrowUpRight></BsArrowUpRight>
                    </p>
                    <img className="el-image" src={LArc} />
                    <div className="el-button-group">
                      <button className="el-button">Spa</button>
                    </div>
                  </div>
                  <div className="el-image-shadow">
                    <img className="shadow" src={LArc} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-feature-button">
            <button className="btn btn-primary ">
              <div className="btn-text">Read More</div>
              <div className="btn-icon">
                <BsArrowRightCircleFill size={20} />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="wrapper-bg">
        <div className="parallax-section parallax-web">
          <Parallax
            className="parallax"
            blur={0}
            bgImage={parallaxBg}
            strength={800}
            bgImageStyle={{
              maxWidth: "calc(1366px - 120px)",
              height: "auto",
              padding: "0px 60px",
              top: "25%",
            }}
          >
            <div className="parallax-content">
              <div className="parallax-content-group parallax-content-1">
                <div className="parallax-image-1">
                  <img
                    className="parallax-image-url parallax-image-url-1"
                    src={wellnessPillars1}
                  ></img>
                </div>
                <div className="parallax-text-section-1">
                  <div className="parallax-text parallax-text-1 ">
                    <div className="parallax-title section-detail-large">
                      Appearance
                    </div>
                    <div className="parallax-text-desc section-detail ">
                      We celebrate self-expression and inner beauty, offering
                      transformative products and fostering confidence.
                    </div>
                  </div>
                </div>
              </div>
              <div className="parallax-content-group parallax-content-2">
                <div className="parallax-image-2">
                  <img
                    className="parallax-image-url parallax-image-url-2"
                    src={wellnessPillars2}
                  ></img>
                </div>
                <div className="parallax-text-section-2">
                  <div className="parallax-text parallax-text-2">
                    <div className="parallax-title section-detail-large">
                      Nutrition
                    </div>
                    <div className="parallax-text-desc section-detail">
                      We prioritize mindful eating and provide delicious,
                      functional foods to nourish your body from within.
                    </div>
                  </div>
                </div>
              </div>
              <div className="parallax-content-group parallax-content-3">
                <div className="parallax-image-3">
                  <img
                    className="parallax-image-url parallax-image-url-3"
                    src={wellnessPillars3}
                  ></img>
                </div>
                <div className="parallax-text-section-3">
                  <div className="parallax-text parallax-text-3">
                    <div className="parallax-title section-detail-large">
                      Health
                    </div>
                    <div className="parallax-text-desc section-detail">
                      We champion healthy habits and a balanced lifestyle
                      through innovative products, education, and support.
                    </div>
                  </div>
                </div>
              </div>
              <div className="parallax-content-group parallax-content-4">
                <div className="parallax-image-4">
                  <img
                    className="parallax-image-url parallax-image-url-4"
                    src={wellnessPillars4}
                  ></img>
                </div>
                <div className="parallax-text-section-4">
                  <div className="parallax-text parallax-text-4">
                    <div className="parallax-title section-detail-large">
                      Fitness
                    </div>
                    <div className="parallax-text-desc section-detail">
                      We encourage movement and activity, offering solutions to
                      inspire and empower your fitness journey.
                    </div>
                  </div>
                </div>
              </div>
              <div className="parallax-content-group parallax-content-5">
                <div className="parallax-image-5">
                  <img
                    className="parallax-image-url parallax-image-url-5"
                    src={wellnessPillars5}
                  ></img>
                </div>
                <div className="parallax-text-section-5">
                  <div className="parallax-text parallax-text-5">
                    <div className="parallax-title section-detail-large">
                      Sleep
                    </div>
                    <div className="parallax-text-desc section-detail">
                      We recognize the importance of quality sleep for overall
                      well-being, offering solutions for relaxation and
                      rejuvenation.
                    </div>
                  </div>
                </div>
              </div>
              <div className="parallax-content-group parallax-content-6">
                <div className="parallax-image-6">
                  <img
                    className="parallax-image-url parallax-image-url-6"
                    src={wellnessPillars6}
                  ></img>
                </div>
                <div className="parallax-text-section-6">
                  <div className="parallax-text parallax-text-6">
                    <div className="parallax-title section-detail-large">
                      Mindfulness
                    </div>
                    <div className="parallax-text-desc section-detail">
                      We cultivate inner peace and well-being through practices
                      and tools that nurture your mind and spirit.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
        </div>
        <div className="parallax-section parallax-mobile">
          <img src={parallaxBg} className="parallax-img-mobile"></img>
          <div className="parallax-content">
            <div className="parallax-content-group parallax-content-1">
              <div className="parallax-image-1">
                <img
                  className="parallax-image-url parallax-image-url-1"
                  src={wellnessPillars1}
                ></img>
              </div>
              <div className="parallax-text-section-1">
                <div className="parallax-text parallax-text-1">
                  <div className="parallax-title section-detail-large">
                    Appearance
                  </div>
                  <div className="parallax-text-desc section-detail">
                    We celebrate self-expression and inner beauty, offering
                    transformative products and fostering confidence.
                  </div>
                </div>
              </div>
            </div>
            <div className="parallax-content-group parallax-content-2">
              <div className="parallax-image-2">
                <img
                  className="parallax-image-url parallax-image-url-2"
                  src={wellnessPillars2}
                ></img>
              </div>
              <div className="parallax-text-section-2">
                <div className="parallax-text parallax-text-2">
                  <div className="parallax-title section-detail-large">
                    Nutrition
                  </div>
                  <div className="parallax-text-desc section-detail">
                    We prioritize mindful eating and provide delicious,
                    functional foods to nourish your body from within.
                  </div>
                </div>
              </div>
            </div>
            <div className="parallax-content-group parallax-content-3">
              <div className="parallax-image-3">
                <img
                  className="parallax-image-url parallax-image-url-3"
                  src={wellnessPillars3}
                ></img>
              </div>
              <div className="parallax-text-section-3">
                <div className="parallax-text parallax-text-3">
                  <div className="parallax-title section-detail-large">
                    Health
                  </div>
                  <div className="parallax-text-desc section-detail">
                    We champion healthy habits and a balanced lifestyle through
                    innovative products, education, and support.
                  </div>
                </div>
              </div>
            </div>
            <div className="parallax-content-group parallax-content-4">
              <div className="parallax-image-4">
                <img
                  className="parallax-image-url parallax-image-url-4"
                  src={wellnessPillars4}
                ></img>
              </div>
              <div className="parallax-text-section-4">
                <div className="parallax-text parallax-text-4">
                  <div className="parallax-title section-detail-large">
                    Fitness
                  </div>
                  <div className="parallax-text-desc section-detail">
                    We encourage movement and activity, offering solutions to
                    inspire and empower your fitness journey.
                  </div>
                </div>
              </div>
            </div>
            <div className="parallax-content-group parallax-content-5">
              <div className="parallax-image-5">
                <img
                  className="parallax-image-url parallax-image-url-5"
                  src={wellnessPillars5}
                ></img>
              </div>
              <div className="parallax-text-section-5">
                <div className="parallax-text parallax-text-5">
                  <div className="parallax-title section-detail-large">
                    Sleep
                  </div>
                  <div className="parallax-text-desc section-detail">
                    We recognize the importance of quality sleep for overall
                    well-being, offering solutions for relaxation and
                    rejuvenation.
                  </div>
                </div>
              </div>
            </div>
            <div className="parallax-content-group parallax-content-6">
              <div className="parallax-image-6">
                <img
                  className="parallax-image-url parallax-image-url-6"
                  src={wellnessPillars6}
                ></img>
              </div>
              <div className="parallax-text-section-6">
                <div className="parallax-text parallax-text-6">
                  <div className="parallax-title section-detail-large">
                    Mindfulness
                  </div>
                  <div className="parallax-text-desc section-detail">
                    We cultivate inner peace and well-being through practices
                    and tools that nurture your mind and spirit.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="gallery-section">
          <div className="section-title-gallery-group">
            <div className="section-title-gallery">
             
              <div className="section-title">Stay in the loop.</div>
            </div>
            <div className="section-desc-gallery section-detail">
              While guarding against commercialization, we embrace
              technology-enhanced mindfulness experiences, seamlessly
              integrating them into daily routines for balance and tranquility.
            </div>
          </div>
          <Gallery></Gallery>

          <div className="section-gallery-button">
            <div className="gallery-button-text section-detail">
              Join Our Community
            </div>
            <div className="gallery-button">
              <button
                className="btn btn-hero"
                onClick={() => setModalOpen(true)}
              >
                <div className="btn-text"> Become a BA Today </div>

                <div className="btn-icon">
                  <BsArrowRightCircleFill size={20} />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <ModalContent></ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default Home;
