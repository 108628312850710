const faq = [
  {
    title: "Business Partnerships",
    subtitle:
      "What are the benefits of becoming a business associate for Lavisha Malaysia?",
    description:
      " By becoming a Business Associate, you can gain deeper self-awareness. You will be able to unlock your potential and discover your true capabilities. You just have to get in touch with our customer service via phone number: +60 12 512 4162 and a representative will attend to you to guide your journey in this career.",
  },
  {
    title: "Events and Collaborations",
    subtitle: "How can I collaborate with Lavisha Malaysia?",
    description:
      "Lavisha encourages individuals to unearth their dreams and latent abilities, creating their own convergence plan and unique convergence recipe, achievement the dual goals of generating revenue and supporting others. If you have an event in mind, please reach out to our customer service via phone number: +60 12 512 4162. ",
  },
  {
    title: "Benefits",
    subtitle:
      "Is there a loyalty program / membership for individuals interested?  ",
    description:
      "  Yes, Lavisha ecosystem offers prestige benefits and rewards for our loyal customers. ",
  },
];

export default faq;
