import React, { useState } from "react";
import Tab from "../components/tab";
import Tabs from "../components/tabs";
import Gallery from "../components/gallery";

import highlight from "../assets/data/articleHighlight";
import activity from "../assets/data/articleActivity";
import all from "../assets/data/articleAll";
import events from "../assets/data/articleEvent";
import workshops from "../assets/data/articleWorkshop";

const News = () => {
  const [active, setActive] = useState(0);

  const handleChange = (newActive) => setActive(newActive);



  return (
    <div className="bg-light-grey">
      <div>
        <div className="section-vw100 section-news-shadow">
          <div className="">
            <div className="section section-news-header">
              <div className="news-header-text">
                <div className="section-item-1">
                  <div className="section-title">Ecosystem</div>
                </div>

                <div className="section-item-2">
                  <div className="section-detail color-text-dark">
                    Lavisha is not just a brand, it is a fertile ecosystem where
                    holistic solutions blossom for everyone. Within this
                    integrated platform, we seamlessly connect individuals to a
                    range of meticulously curated services, providing end-to-end
                    solutions for your overall health, tailored to your
                    schedule, receive personalized nutritional guidance based on
                    your preferences, and participate in expert-led mindfulness
                    sessions-all within the Lavisha ecosystem.
                  </div>
                </div>
              </div>
            </div>

            <div className="news-header-image">
              <div className="news-header-image-group">
                <div className="header-img"></div>
              </div>
            </div>
          </div>

          <div>
            <div className="section section-news">
              <div className="section-news-text">
                <div className="section-title">Current Happenings</div>
              </div>

              <div className="section-news-content">
                <Tabs active={active} onChange={handleChange}>
                  <Tab title="All">
                    <div className="news-article">
                      {all.map((article, index) => (
                        <div className="article-card" key={index}>
                          <div className="article-date section-detail-small color-text-grey">
                            {article.date}
                          </div>
                          <div className="article-img-box">
                            <img className="article-img" src={article.src} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </Tab>
                  <Tab title="Events">
                  <div className="news-article">
                      {events.map((article, index) => (
                        <div className="article-card" key={index}>
                          <div className="article-date section-detail-small color-text-grey">
                            {article.date}
                          </div>
                          <div className="article-img-box">
                            <img className="article-img" src={article.src} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </Tab>
                  <Tab title="Activities">
                    <div className="news-article">
                      {activity.map((article, index) => (
                        <div className="article-card" key={index}>
                          <div className="article-date section-detail-small color-text-grey">
                            {article.date}
                          </div>
                          <div className="article-img-box">
                            <img className="article-img" src={article.src} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </Tab>
                  {/* <Tab title="Upcomings">
                    <div className="news-article">
                      {highlight.map((article, index) => (
                        <div className="article-card" key={index}>
                          <div className="article-date section-detail-small color-text-grey">
                            {article.date}
                          </div>
                          <div className="article-img-box">
                            <img className="article-img" src={article.src} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </Tab> */}
                  <Tab title="Workshops">
                    <div className="news-article">
                      {workshops.map((article, index) => (
                        <div className="article-card" key={index}>
                          <div className="article-date section-detail-small color-text-grey">
                            {article.date}
                          </div>
                          <div className="article-img-box">
                            <img className="article-img" src={article.src} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>

        <div className="section-vw100 section-event-shadow bg-primary">
          <div className="section-event">
            <div>
              <div className="section section-past-event">
                <div className="section-past-event-text">
                  <div className="section-tag ">
                    <div className="dot bg-secondary"></div>Upcoming
                  </div>
                  <div className="section-title">Highlight</div>
                </div>

                <div className="section-past-event-content">
                  <div className="past-event">
                    {highlight.map((pastEvent, index) => (
                      <div className="pastEvent-card" key={pastEvent.id}>
                        <div className="pastEvent-img-box">
                          <img className="pastEvent-img" src={pastEvent.src} />

                          <div className="pastEvent-button-group">
                            <button className="pastEvent-button">
                              {pastEvent.type}
                            </button>
                          </div>
                        </div>

                        <div className="pastEvent-title-group">
                          <div className="pastEvent-title section-detail-large">
                            {pastEvent.title}
                          </div>

                          <div className="pastEvent-date section-detail-small  color-text-grey">
                            {pastEvent.date}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-vw100 ">
        <div className="section-gallery">
          <Gallery></Gallery>
        </div>
      </div>
    </div>
  );
};

export default News;
